import React from "react"
import { Media } from "../media"
import Seo from "./seo"
import LanguageSelector from "./languageSelector"
import {
  accordionContent,
  heading,
  section,
  paragraph,
  link,
} from "./home.module.css"

const Home = ({ pathname, langKey, exName, onClick }) => {
  return (
    <div className={accordionContent}>
      <Seo />
      <h2 className={heading}>Masters of fine Arts</h2>
      <div className={section}>
        <LanguageSelector
          pathname={pathname}
          langKey={langKey}
          exName={exName}
        />
      </div>

      <Media at="sm">
        {langKey === "de" ? (
          <German
            artistTab={"unteren"}
            infoTab={"oberen"}
            onClick={index => onClick(index)}
          />
        ) : (
          <English
            artistTab={"below"}
            infoTab={"above"}
            onClick={index => onClick(index)}
          />
        )}
      </Media>
      <Media greaterThan="sm">
        {langKey === "de" ? (
          <German
            artistTab={"rechten"}
            infoTab={"linken"}
            onClick={index => onClick(index)}
          />
        ) : (
          <English
            artistTab={"to the right"}
            infoTab={"to the left"}
            onClick={index => onClick(index)}
          />
        )}
      </Media>
    </div>
  )
}

export default Home

const German = ({ artistTab, infoTab, onClick }) => {
  return (
    <div className={section}>
      {/* <p className={paragraph}>
        Wählen Sie eine(n) Künstler*in aus dem {artistTab} Kartenreiter aus.
      </p> */}
      <p className={paragraph}>
        Klicken Sie{" "}
        <span
          className={link}
          onClick={() => onClick(2)}
          onKeyDown={() => onClick(2)}
          role="link"
          tabIndex={0}
        >
          hier
        </span>{" "}
        oder auf den {artistTab} Kartenreiter mit der Kennzeichnung »A«, um zu
        den Künstler*innen zu gelangen.
      </p>
      <p className={paragraph}>
        Klicken Sie{" "}
        <span
          className={link}
          onClick={() => onClick(0)}
          onKeyDown={() => onClick(0)}
          role="link"
          tabIndex={-1}
        >
          hier
        </span>{" "}
        oder auf den {infoTab} Kartenreiter mit der Kennzeichnung »M«, um zu den
        Informationen zur Ausstellung zu gelangen.
      </p>
    </div>
  )
}

const English = ({ artistTab, infoTab, onClick }) => {
  return (
    <div className={section}>
      {/* <p className={paragraph}>Choose an artist from the menu {artistTab}.</p> */}
      <p className={paragraph}>
        Click{" "}
        <span
          className={link}
          onClick={() => onClick(2)}
          onKeyDown={() => onClick(2)}
          role="link"
          tabIndex={0}
        >
          here
        </span>{" "}
        or on the tab {artistTab} labeled »A« to get to the artists.
      </p>
      <p className={paragraph}>
        Click{" "}
        <span
          className={link}
          onClick={() => onClick(0)}
          onKeyDown={() => onClick(0)}
          role="link"
          tabIndex={-1}
        >
          here
        </span>{" "}
        or on the tab {infoTab} labeled »M« to get to the information about the
        exhibition.
      </p>
    </div>
  )
}
