import React from "react"
import { graphql } from "gatsby"
import localize from "../components/localize"
import Home from "../components/home"

const Index = ({ pathname, langKey, data, onClick }) => {
  const { sanityExhibition } = data

  return (
    <Home
      pathname={pathname}
      langKey={langKey}
      exName={sanityExhibition.exName}
      onClick={index => onClick(index)}
    />
  )
}

export default localize(Index)

export const query = graphql`
  {
    sanityExhibition(exName: { eq: "2023" }) {
      ...SanityExhibitionFragment
    }
    allSanityExhibition(sort: { fields: exName, order: ASC }) {
      ...SanityExhibitionConnectionFragment
    }
    allSanityArtist(
      filter: {
        participance: { eq: true }
        exhibition: { exName: { eq: "2023" } }
      }
      sort: { fields: lastName, order: ASC }
    ) {
      ...SanityArtistConnectionFragment
    }
    sanitySiteSettings {
      ...SanitySiteSettingsFragment
    }
  }
`
